@font-face {
    font-family: "Vela Sans Regular";
    src: url("./fonts/velasans-regular-webfont.woff2") format("woff2"),
        url("./fonts/velasans-regular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Space Grotesk";
    src: url("./fonts/spacegrotesk-regular-webfont.woff2") format("woff2"),
        url("./fonts/spacegrotesk-regular-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "space_groteskbold";
    src: url("./fonts/spacegrotesk-bold-webfont.woff2") format("woff2"),
        url("./fonts/spacegrotesk-bold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "space_groteskmedium";
    src: url("./fonts/spacegrotesk-medium-webfont.woff2") format("woff2"),
        url("./fonts/spacegrotesk-medium-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "space_grotesksemibold";
    src: url("./fonts/spacegrotesk-semibold-webfont.woff2") format("woff2"),
        url("./fonts/spacegrotesk-semibold-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "space_grotesklight";
    src: url("./fonts/spacegrotesk-light-webfont.woff2") format("woff2"),
        url("./fonts/spacegrotesk-light-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

html,
body,
#root {
    height: 100%;
    text-rendering: optimizeLegibility;
    font-family: "Space Grotesk", sans-serif;
}

body.dark {
    color: #fff;
}

#root,
.App {
    min-height: 100%;
}

.zun-btn {
    background: linear-gradient(0deg, #f96501 0%, #ffa001 100%);
    border-radius: 44px;
    text-align: center;
    color: #ffffff;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-family: "Space Grotesk";
    text-decoration: none;

    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    padding: 10px 30px;

    &:hover {
        text-decoration: none;
        color: #fff;
    }
}

.zun-btn.claimed {
    pointer-events: none;
    opacity: 0.5;
    background: #198754 !important;
}

.top-info {
    font-family: "Space Grotesk";
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
}

.bottom-info {
    font-family: "Space Grotesk";
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #525252;
    width: 250px;
    margin: 30px auto 0 auto;
}

p {
    font-family: "Space Grotesk";
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 1rem;
}

.modal-dialog {
    max-width: 644px;
}

.modal-content {
    border-color: #e6e6e6;
    border-radius: 25px;
}

@media screen and (min-width: 360px) {
    .modal-body {
        padding: inherit;
        padding: 0 10px;
    }

    .top-info {
        margin: 30px auto 30px auto;
    }

    .bottom-info {
        margin: 30px auto 30px auto;
    }

    .steps {
        margin: 0 auto;
    }

    .pool-logo {
        width: 26px;
        height: auto;
    }
}

@media screen and (min-width: 1024px) {
    .modal-body {
        padding: 40px 60px;
    }

    .top-info {
        margin: 1rem auto 30px auto;
    }

    .bottom-info {
        margin: 30px auto 0 auto;
    }

    .steps {
        margin: 0 auto;
    }

    .pool-logo {
        width: 42px;
        height: auto;
    }
}

.steps {
    border-radius: 20px;
    background: #f8f8f8;
    padding: 28px 18px;
    max-width: 466px;
}

.attention-claim {
    color: #000;
    text-align: center;
    font-family: "Space Grotesk";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 132%; /* 15.84px */
    width: 280px;
    margin: 30px auto 0 auto;
}

.WalletsModal button {
    width: 126px;
    height: 153px;
    border-radius: 20px !important;
    border: none;
    padding: 15px;
}

.WalletsModal button .name {
    font-family: "Space Grotesk";
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    margin-right: 20px;
    margin-top: 15px;
}

.WalletsModal button .connect {
    background: #fff;
    color: #000;
    border-radius: 20px;
    padding: 6px 12px;
    font-size: 11px;
    line-height: 14px;
    text-align: center;
    margin-top: 25px;
}

.WalletsModal button .connect img {
    width: auto;
    height: 22px;
}

.WalletsModal button.metamask {
    background: linear-gradient(255.16deg, #ffb800 3.65%, #ffa800 94.22%);
    color: #fff;
}

.WalletsModal button.walletconnect {
    background: linear-gradient(80.97deg, #0e75fe 6.89%, #4c95f7 138.91%);
    color: #fff;
}

.WalletsModal button.coinbase {
    background: linear-gradient(78.23deg, #f9f9f9 6.99%, #fbfbfb 98.23%);

    span {
        color: #000 !important;
    }
}

.WalletsModal button.zerion {
    background: linear-gradient(80.97deg, #dce6ff 6.89%, #e9efff 138.91%);

    span {
        color: #000 !important;
    }
}

#wallets-modal {
    background-color: rgba(0, 0, 0, 0.5);
}

#wallets-modal .modal-body {
    padding: 40px;
}

.claim-props {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.claim-props .panel {
    max-width: 180px;
    height: 64px;
    border-radius: 20px;
    background: #e0e0e0;
    text-align: left;
    padding: 11px 20px;
}

.claim-props .panel .title {
    color: #000;
    font-family: "Space Grotesk";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.claim-props .panel .value {
    color: #000;
    font-family: "Vela Sans Regular";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.itoggle {
    max-height: 0;
    max-width: 0;
    opacity: 0;
    position: absolute;
}
input.itoggle + label {
    display: block;
    position: relative;
    box-shadow: inset 0 0 0 1px #d5d5d5;
    height: 26px;
    width: 48px;
    border-radius: 15px;
    margin-bottom: 0;
}
input.itoggle + label:before {
    content: "";
    position: absolute;
    display: block;
    height: 26px;
    width: 26px;
    top: 0;
    left: 0;
    border-radius: 15px;
    background: rgba(19, 191, 17, 0);
    transition: 0.25s ease-in-out;
}
input.itoggle + label:after {
    content: "";
    position: absolute;
    display: block;
    height: 26px;
    width: 26px;
    top: 0;
    left: 0;
    border-radius: 15px;
    background: white;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: 0.25s ease-in-out;
}
input.itoggle:checked + label:before {
    width: 46px;
    background: rgba(19, 191, 17, 1);
    content: "";
}
input.itoggle:checked + label:after {
    left: 20px;
    box-shadow: inset 0 0 0 1px rgba(19, 191, 17, 1),
        0 2px 4px rgba(0, 0, 0, 0.2);
}
input.itoggle:disabled + label {
    opacity: 0.5;
}

input.itoggle.orange:checked + label:before {
    background: #9f9f9f;
}
input.itoggle.orange:checked + label:after {
    box-shadow: inset 0 0 0 1px #9f9f9f, 0 2px 4px rgba(201, 48, 44, 0.2);
}

.coin-selector {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.coin-selector.panel {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.btn.disabled {
    background: #b5b5b5 !important;
    color: #fff !important;
    pointer-events: none;
}

#wcm-modal {
    z-index: 99999999;
}

.modal {
    z-index: 1;
}
